
/* =================================
   -- Layout
   ================================= */

body{
	background-color: #fff;
}

.inverted{
	background-color: @gray-dark;

	* {
		color: #fff;
	}
}

::selection {
	background: @brand-primary;
}

//.flip{
//	transform: scaleX(-1);
//
//	filter: FlipH;
//	-ms-filter: "FlipH";
//}

.accent{
	color: @brand-primary;
}

//.logo-stripped{
//	padding:2em;
//	#logo-circle,
//	g{
//		display:none;
//	}
//}

hr {
	border-top-color: lighten(@gray-base, 76.7%);;
}

p {
	font-size: 1.6rem;

	@media (min-width: @screen-sm-min) {
		font-size: 1.8rem;
	}
	//word-break: break-all;
    word-break: break-word;
	hyphens: auto;
	//text-align: justify;
}

li {
	font-size: 1.6rem;

	@media (min-width: @screen-sm-min) {
		font-size: 1.8rem;
	}
}

blockquote{
	border-left: 0 none;
	//font-style: italic;
	font-family: @font-family-serif;
	color:@gray-light;
	font-weight:100;
	font-size: 2.1rem;
	padding-left: 0;
	padding-right: 0;

	footer{
		font-family: @font-family-sans-serif;
		font-size:.75em;

		&::before{
			content:""
		}
	}
}


.container{
	padding-left:25px;
	padding-right:25px;

	@media (min-width: @screen-md-min) {
		padding-left:35px;
		padding-right:35px;
	}
}




h2{

	font-size: 4.2rem;

	@media (min-width: @screen-sm-min) {
		font-size: 5.6rem;
	}

	small{
		font-size: 50%;
	}

}


h3{

	font-size: 2.3rem;
	//color: @gray-light;

	@media (min-width: @screen-sm-min) {
		font-size: 2.8rem;
	}

	font-weight: 400;
	margin-bottom: 1em;

	small{
		display:block;
	}
}

.content-header{

	//font-size:300%;
	margin: 11.5% 0 9.5%;

	font-weight:bold;
	//text-align: center;

	@media (min-width: @screen-sm-min) {
		//font-size:400%;
		margin: 4.5% 0 5%;
		//margin: 2.5% 0 2.5%;
	}

	small{
		display:block;
		color: @gray-light;
		margin-top: 1em;
		margin-bottom: .5em;
		//margin-top: 2em;
		//font-size:50%;
		//font-weight: 400;
	}

}


h3.content-header{
	margin: 3% 0 1.5%;;

}


h2.content-header-like-small {
	margin-bottom:5.57666111%;

	@media (min-width: @screen-sm-min) {
		margin-bottom: 2.5%;
	}
}

.content-header-like-small + .row h3{
	margin-top:0;
	color: @gray-light;
}

//h2:extend(.content-header){}
//
//.content-header-small-like{
//	&:extend(.content-header small);
//}


/* =================================
   ---- General
   ================================= */


.multi-col {

	@media (min-width: @screen-md-min) {
		column-width:45%;
		column-count: 2;
		column-gap: 4.5em;

		background: transparent url(../assets/logo-kreis.svg) no-repeat 50% calc( ~"50% - 1.5em");
	}

	a{
		border:3px solid #fff;

		@media (min-width: @screen-md-min) {
			border:0 none;

			&:hover{
				background-color: transparent !important;
			}
		}

		background: transparent;
		break-inside: avoid;

		margin-bottom:1.2em;

		font-weight: 600;
		font-size:125%;

		@media (min-width: @screen-sm-min) {
			margin-bottom:1.5em;
			font-size:150%;
		}

	}
}


.section{
	padding-bottom:2.5em;
}

// give a padding to all section
section .container:extend(.section){}

/* =================================
   ---- Intro
   ================================= */

#intro{

	position: relative;

	.container{
		//background: #fff;
	}
}


/* =================================
   ---- About
   ================================= */

#about{
	.container{
		//padding-bottom:calc(~"2.5em + 70px");
	}

	@media (min-width: @screen-md-min) {
		ul{
			li:nth-child(-n+5){
				text-align:right;
			}

			li:nth-child(3){
				width: calc(~"100% - 25%");
			}

			li:nth-child(8){
				margin-left: calc(~"100% - 75%");
			}

			li:nth-child(2),
			li:nth-child(4)
			{
				width: calc(~"100% - 15%");
			}

			li:nth-child(7),
			li:nth-child(9)
			{
				margin-left: calc(~"100% - 85%");
			}
		}
	}
}

//#fachgebiete{
//	h3:before{
//		content: " ";
//		margin-top: -70px;
//		padding-bottom: 70px;
//		visibility: hidden;
//		display:block;
//	}
//}


/* =================================
   ---- Team
   ================================= */



.team-avatar{
	object-fit: cover;
	//height: 240px;
	width: 100%;
}

.vcard{
	font-size:120%;
	text-align:center;
	@media (min-width: @screen-sm-min) {

		&.text-center{
			text-align:right;
		}

	}
}

.logo-team{

	width: 170px;
	height: 170px;
	margin:2em auto;

	@media (min-width: @screen-sm-min) {
		width: 220px;
		height: 210px;
		margin:0;
	}

	svg{
		width:100%;
		height:100%;
	}

	img{
		width:100%;
	}
}

.team{
	blockquote{
		font-size:2rem;
	}
}


/* =================================
   ---- Download
   ================================= */

.two-column{
	@media (min-width: @screen-md-min) {
		column-width:45%;
		column-count: 2;
		column-gap: 4.5em;
	}

	padding-left: 0;
	margin-left: 0;

	list-style: none;

	li{
		padding: 0 0 1em;
		display: inline-block;
		width: 100%;
	}
}

/* =================================
   ---- Map
   ================================= */

.map-wrap {
	position: relative;
	height: 400px;
	//margin-top: 60px;
}

.map-wrap:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 6px;
	background: transparent;
	background: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, 0));
}

.map-wrap > div {
	width: 100%;
	height: 100%;
	//filter: grayscale(100%);
}

.map-static{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* =================================
   ---- Contact
   ================================= */

#contact-form{
	#url {
		position: absolute;
		left: -2000px;
		visibility: hidden;
	}
}



/* =================================
   ---- Aktuelles
   ================================= */

#aktuelles h3:extend(.content-header){
	text-align:left;
}

.post{
	border-bottom:1px solid #eee;
	margin-bottom:1.75em;
	padding-bottom:1.5em;
}

.post:last-child{
	border-bottom:0 none;
	padding-bottom:0;
	margin-bottom:0;
}

/* =================================
   ---- Kanzlei
   ================================= */

#kanzlei{
	.row{
		margin-bottom:4em;
	}
}



/* =================================
   ---- Footer
   ================================= */

footer{
	padding-top: 15px;
	padding-bottom: 15px;
}
