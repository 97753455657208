/* =================================
   -- Navbar
   ================================= */
.navbar-inverse{
	border:0 none;
	//background-color: rgba(0,0,0,.3);

	.navbar-nav {

		text-shadow: 0px 0 5px rgba(0,0,0,.3);

		> li > a {

			font-size:20px;
			font-weight:500;

			color: lighten(@gray-light, 75%);

			@media (min-width: @screen-sm-min) {

				padding-left:0;
				padding-right:0;

				margin-left: 15px;
				margin-right: 15px;

				// animated underline on hover
				&:before{
					content: "";
					position: absolute;
					width: 100%;
					height: 3px;
					bottom: 0;
					left: 0;
					background-color: #fff;
					visibility: hidden;
					transform: scaleX(0);
					transition: all 0.1s ease-in-out 0s;
				}

				&:hover:before {
					visibility: visible;
					transform: scaleX(1);
				}
			}

		}

	}

}


@media (min-width: @screen-sm-min) {

	.navbar {

		transition: background-color .5s;

		margin-bottom: 0;
		//position:relative;

		position: fixed;
		top:0;
		right: 0;
		left:0;
		z-index: 5;

		&.affix{
			background-color: fade(@brand-secondary, 95%);
		}

	}

	.navbar-inverse {
		border:0 none;
		background-color: fade(@brand-secondary, 50%);
		background-color: transparent;
	}


	// Brand

	.navbar{

		.navbar-brand{
			display: none;
		}

		&.affix{
			.navbar-brand{
				display: block;
			}
		}
	}

}

.network{
	margin-left: 15px;
	position: absolute;
	bottom: 10px;
}
