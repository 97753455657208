
/* =================================
   -- Slideout Menu
   ================================= */

@media (max-width: @screen-xs-max) {

	.btn-toggle-menu{

		position: absolute;
		z-index: 10;

		svg{
			width: 20px;
			height: 20px;
			margin-top: 7px;

			path{
				fill:#fff;
			}
		}
	}

	.slideout-menu {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 256px;
		min-height: 100vh;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		z-index: 0;
		display: none;
	}

	.slideout-menu-left {
		left: 0;
	}

	.slideout-menu-right {
		right: 0;
	}

	.slideout-panel {
		position: relative;
		z-index: 1;
		will-change: transform;
		background-color: #FFF;
		min-height: 100vh;
	}

	.slideout-open,
	.slideout-open body,
	.slideout-open .slideout-panel {
		overflow: hidden;
	}

	.slideout-open .slideout-menu {
		display: block;
	}

}
