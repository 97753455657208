

/* =================================
   -- Slider (Slick)
   ================================= */


.slider{
	// set stack
	position: relative;

	height: 275px;

	background-color: @gray-dark;

	div{
		height: 275px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		background-position: center 0;

		//filter:grayscale(100);
	}
}

@media (min-width: @screen-sm-min) {
	.slider{

		height: 450px;

		div{
			height: 450px;
		}
	}
}

@media (min-width: @screen-md-min) {
	.slider{

		height: 850px;

		div{
			height: 850px;
		}
	}
}
