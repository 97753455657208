.logo-top{

	width:100px;
	//margin-left:15px;
	//margin-top:15px;

	filter: drop-shadow(0px 0px 1px rgba(0,0,0,0.2));

	//display: none;

	&:before{
		//content: attr(title);
		//width: 100%;
		//height: 50px;
		//display: block;
		//color: #fff;
	}


	@media (min-width: @screen-sm-min) {

		position: absolute;
		z-index:6;

		top:50px;
		left:15px;

		//height: 250px;
		width:100px;

		margin:0 auto;



		display:block;

		top:20px;
		left:30px;

		height: 200px;
		width:200px;
	}


	@media (min-width: @screen-lg-min) {
		top:20px;
		left:50px;

		height: 250px;
		width:300px;
	}

	img{
		width:100%;
		//filter: drop-shadow( 0px 0px 1px rgba(0,0,0,.1) );
	}
}

nav{
	//background-color: @brand-primary;
}

svg.asset-logo-invert{
	display: block;
	width: 100%;
	height: 100%;

	@media (max-width: @screen-xs-max) {
		.path-textmarke{
			display: none;
		}
	}

}
.textmarke{
	font-family: "News Cycle", @font-family-sans-serif;
	color: @brand-primary !important;
}

main .textmarke{
	position:absolute;
	left:46px;
	height:46px;
	line-height: 46px;
	font-size:1.6rem;

	z-index: 2;
}

.mobile-header{
	position: relative;

	&.affix{
		position:sticky;
	}

	width: 100%;
	background: #3d3d3c;
	height: 45px;
	z-index: 2;
	top: 0px;
}

.navbar-brand {

	@media (max-width: @screen-xs-max) {
		height:auto;
	}

	@media (min-width: @screen-sm-min) {
		margin-left:20px !important;
	}
}

/**
 * Sub Pages
 */
.hero-header{
	position: relative;

	background-color: @gray-dark;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	// background-blend-mode: overlay;

	height:275px;

	@media (min-width: @screen-sm-min) {
		height:420px;
	}

	@media (min-width: @screen-lg-min) {
		height:575px;
	}


	&::after{
		content: "";
		background: #000;
		opacity: 0.2;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;

	}
}

#page-kanzlei{
	.hero-header{
		background-position:  center 0px;
		@media (min-width: @screen-lg-min) {
			background-position:  center -10px;
		}
	}
}

#page-aktuelles{
	.hero-header{
		background-position:  0 0;

		@media (min-width: @screen-lg-min) {
			background-position:  center -140px;
		}
	}
}

#page-kontakt{
	.hero-header{

		@media (max-width: @screen-xs-max) {
			background-size: inherit;
		}

		background-position:  center 100%;
	}
}

#page-downloads{
	.hero-header{
		background-position:  center 0px;

		@media (min-width: @screen-lg-min) {
			background-position:  center 60%;
		}

		@media (max-width: @screen-xs-max) {
			background-size: inherit;
			background-position: 75% 60%;
		}

	}
}
