@charset "UTF-8";
/*

Animate.less
Cross-browser LESS animation library for Bootstrap
Licensed under the ☺ license (http://licence.visualidiot.com/)
Documenation: https://github.com/machito/animate.less
-------------------------------------------------------
Copyright © 2012 Michael Castilla
Email: yo@machito.co
Twitter: https://twitter.com/micr0bitz

*/

@animationDurationTime: 1s;
@animationDelayTime: 0s;
@animationFillMode: both;
@animationLessLocation: '../vendor/animate.less/source';



// required for all
@import "@{animationLessLocation}/animated.less";

.animated{
	.animated;
}


//@import "@{animationLessLocation}/bounce.less";
//@import "@{animationLessLocation}/bounceIn.less";
//@import "@{animationLessLocation}/bounceInDown.less";
//@import "@{animationLessLocation}/bounceInLeft.less";
//@import "@{animationLessLocation}/bounceInRight.less";
@import "@{animationLessLocation}/bounceInUp.less";
//@import "@{animationLessLocation}/bounceOut.less";
//@import "@{animationLessLocation}/bounceOutDown.less";
//@import "@{animationLessLocation}/bounceOutLeft.less";
//@import "@{animationLessLocation}/bounceOutRight.less";
//@import "@{animationLessLocation}/bounceOutUp.less";
//@import "@{animationLessLocation}/fadeIn.less";
//@import "@{animationLessLocation}/fadeInDown.less";
//@import "@{animationLessLocation}/fadeInDownBig.less";
//@import "@{animationLessLocation}/fadeInLeft.less";
//@import "@{animationLessLocation}/fadeInLeftBig.less";
//@import "@{animationLessLocation}/fadeInRight.less";
//@import "@{animationLessLocation}/fadeInRightBig.less";
//@import "@{animationLessLocation}/fadeInUp.less";
//@import "@{animationLessLocation}/fadeInUpBig.less";
//@import "@{animationLessLocation}/fadeOut.less";
//@import "@{animationLessLocation}/fadeOutDown.less";
//@import "@{animationLessLocation}/fadeOutDownBig.less";
//@import "@{animationLessLocation}/fadeOutLeft.less";
//@import "@{animationLessLocation}/fadeOutLeftBig.less";
//@import "@{animationLessLocation}/fadeOutRight.less";
//@import "@{animationLessLocation}/fadeOutRightBig.less";
//@import "@{animationLessLocation}/fadeOutUp.less";
//@import "@{animationLessLocation}/fadeOutUpBig.less";
//@import "@{animationLessLocation}/flash.less";
//@import "@{animationLessLocation}/flip.less";
//@import "@{animationLessLocation}/flipInX.less";
//@import "@{animationLessLocation}/flipInY.less";
//@import "@{animationLessLocation}/flipOutX.less";
//@import "@{animationLessLocation}/flipOutY.less";
//@import "@{animationLessLocation}/hinge.less";
//@import "@{animationLessLocation}/lightSpeedIn.less";
//@import "@{animationLessLocation}/lightSpeedOut.less";
//@import "@{animationLessLocation}/pulse.less";
//@import "@{animationLessLocation}/rollIn.less";
//@import "@{animationLessLocation}/rollOut.less";
//@import "@{animationLessLocation}/rotateIn.less";
//@import "@{animationLessLocation}/rotateInDownLeft.less";
//@import "@{animationLessLocation}/rotateInDownRight.less";
//@import "@{animationLessLocation}/rotateInUpLeft.less";
//@import "@{animationLessLocation}/rotateInUpRight.less";
//@import "@{animationLessLocation}/rotateOut.less";
//@import "@{animationLessLocation}/rotateOutDownLeft.less";
//@import "@{animationLessLocation}/rotateOutDownRight.less";
//@import "@{animationLessLocation}/rotateOutUpLeft.less";
//@import "@{animationLessLocation}/rotateOutUpRight.less";
//@import "@{animationLessLocation}/shake.less";
//@import "@{animationLessLocation}/swing.less";
//@import "@{animationLessLocation}/tada.less";
//@import "@{animationLessLocation}/wiggle.less";
//@import "@{animationLessLocation}/wobble.less";
